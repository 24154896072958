import store, { FeatureToggles } from "@two-ui/store";
import { RouteLocationNormalized } from "vue-router";
import { SIDE_BAR_ITEMS } from "@two-ui/constants";
import { SideBarItemsType } from "@two-ui/types";
import { useGlobalStore } from "@two-ui/global-store";

export class FeatureToggleNavigationError extends Error {
  constructor(message?: string) {
    super(message);
  }
}
/**
 * Checks if route requires feature toggle and router meta is set.
 * If it requires feature toggle and router meta not set, if route opened directly via url, it returns to home page. If opened
 * using router navigation, throws customer error that can be handled using router.onError e.g. to show some feedback.
 * Returns undefined if there is no issue with performing the navigation.
 * @param to
 * @param from
 * @returns route or undefined
 */
export default async function featureToggleRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  const feature = SIDE_BAR_ITEMS.find((route) => route.routeName === to.name);
  if (feature?.isUserStaff) await store.waitForUserPropertiesToPopulate();
  const requiresMetaRoute = to.meta.requiresFeatureToggle
    ?.feature as keyof FeatureToggles;
  if (
    !isEnabledSideBar(feature) ||
    (requiresMetaRoute && !store.state.featureToggles[requiresMetaRoute])
  ) {
    if (!from.name) return { name: "home" };
    throw new FeatureToggleNavigationError();
  }
}

export const customerHasBalancesEnabled = (feature?: SideBarItemsType) => {
  if (
    feature?.routeName === "currency-exchange" &&
    (!store.state.customerFeatureFlags?.floats ||
      !store.state.customerFeatureFlags?.fx)
  ) {
    return false;
  }
  return true;
};

export const isEnabledSideBar = (feature?: SideBarItemsType) => {
  // If no feature is provided, allow access
  if (!feature) return true;

  // First handle staff features
  if (feature.isUserStaff) {
    // Check if user is staff and feature is enabled via propertyStore
    if (feature.propertyStore) {
      const propertyStore = feature.propertyStore as keyof FeatureToggles;
      return (
        store.state.isUserStaff && store.state.featureToggles[propertyStore]
      );
    }
    return store.state.isUserStaff;
  }

  // Then handle playground mode
  const globalStore = useGlobalStore();
  if (globalStore.playgroundMode) {
    return !!feature.allowInPlaygroundMode;
  }

  // For non-staff features, check propertyStore
  if (!feature.propertyStore) return true;
  const propertyStore = feature.propertyStore as keyof FeatureToggles;
  return store.state.featureToggles[propertyStore];
};
