<template>
  <div class="rounded-2xl p-5 text-black" :class="backgroundColor">
    <div class="flex gap-4">
      <div class="flex gap-2" v-if="customEmoji">
        <div class="p-1">
          <div
            class="color-red flex w-[40px] items-center justify-center rounded-full border-0 bg-lime-100 p-2"
          >
            {{ customEmoji }}
          </div>
        </div>
      </div>
      <div v-if="icon">
        <IconWithBackground
          :icon="icon"
          size="3x"
          class="ml-0.5"
          :class="iconBackgroundColor"
          v-if="type !== 'loading'"
        />
        <div class="relative" v-else>
          <font-awesome-icon
            icon="circle"
            class="fa-2x icon-bg text-cyan-100"
            fixed-width
          />
          <LoadingSpinner
            size="2rem"
            stroke-width="0.8rem"
            class="absolute top-0 p-1.5"
            style="left: 3.5px"
          />
        </div>
      </div>
      <div class="m-1 grow">
        <header class="flex items-center justify-between">
          <b role="heading">{{ header }}</b>
          <button v-if="showCloseButton" @click="$emit('close')">
            <font-awesome-icon :icon="['far', 'times']" />
          </button>
        </header>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { MessageBoxType } from "./types";
import IconWithBackground from "./IconWithBackGround.vue";
import { LoadingSpinner } from "../..";

export default defineComponent({
  components: { IconWithBackground, LoadingSpinner },
  props: {
    header: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<MessageBoxType>,
      required: false,
      default: "default",
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Array as PropType<string[]>,
      required: false,
    },
    customEmoji: {
      type: String,
      required: false,
    },
  },
  emits: ["close"],

  setup(props) {
    const backgroundColor = computed(() => {
      const colors = {
        default: "bg-grey-50",
        info: "bg-cyan-50",
        loading: "bg-cyan-50",
        success: "bg-lime-50",
        warning: "bg-yellow-50",
        error: "bg-red-50",
      };
      return colors[props.type] || "bg-grey-50";
    });

    const icon = computed(() => {
      if (props.hideIcon) return null;
      if (props.customIcon && props.customIcon.length) return props.customIcon;
      const defaultIcons: Record<MessageBoxType, string[]> = {
        default: ["far", "circle-exclamation"],
        success: ["far", "check"],
        warning: ["far", "triangle-exclamation"],
        error: ["far", "triangle-exclamation"],
        info: ["far", "lightbulb"],
        loading: ["far", "lightbulb"],
      };

      return defaultIcons[props.type] || ["far", "circle-exclamation"];
    });

    const iconBackgroundColor = computed(() => {
      if (props.hideIcon) return null;

      const colors = {
        default: "",
        success: "icon-success",
        warning: "icon-warning",
        error: "icon-danger",
        info: "icon-info",
        loading: "icon-info",
      };

      return colors[props.type] || "icon-bg";
    });

    return {
      backgroundColor,
      icon,
      iconBackgroundColor,
    };
  },
});
</script>
