<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter, RouteRecordName } from "vue-router";
import CustomerSelectDropdown from "@two-ui/components/CustomerSelectDropdown.vue";
import CreateNewRewardLinkButton from "@two-ui/components/common/CreateNewRewardLinkButton.vue";
import store from "../store";
import { CustomerIdMissingNavigationError } from "@two-ui/route-guards/customer-id-restriction";
import Badge from "./common/Badge.vue";
import { TwoSidebar, TwoButton, TwoTag } from "@wegift/two-components";
import { GuardAction, SideBarItemsType, UserPermissions } from "@two-ui/types";
import TwoSidebarItem from "@two-components/components/two-sidebar/TwoSidebarItem.vue";
import { SIDE_BAR_ITEMS } from "@two-ui/constants";
import {
  customerHasBalancesEnabled,
  isEnabledSideBar,
  FeatureToggleNavigationError,
} from "@two-ui/route-guards/feature-toggle-restriction";
import RunaLogoWhite from "@wegift/company-brand-assets/dist/logo-horizontal-white.svg";
import { hasPermission } from "../directives/permission";
import { useGlobalStore } from "@two-ui/global-store";
import { CustomerSource } from "@workspace/open-api/cms";

const router = useRouter();
const route = useRoute();
const globalStore = useGlobalStore();

// it used to be 130px which meant only 3 child items were visible
const childAccordionListHeight = "1000px";

router.onError((error) => {
  if (error instanceof CustomerIdMissingNavigationError) {
    alert("Please select customer id to continue");
  }
  if (error instanceof FeatureToggleNavigationError) {
    alert("Access denied");
  }
});

const logout = () => router.push({ name: "auth-logout" });
const isUserStaff = computed(() => store.state.isUserStaff);
const customerIsMigratedFromConnect = computed(
  () => globalStore.customerSource === CustomerSource.MIGRATED_FROM_CONNECT
);
const expandedAccordionList = ref<Record<string, boolean>>({});
const initiallyExpanded = ref<Record<string, boolean>>({});

const handleAccordionClick = (routeName: string) => {
  if (initiallyExpanded.value[routeName] !== undefined) {
    initiallyExpanded.value[routeName] = !initiallyExpanded.value[routeName];
  }
  expandedAccordionList.value[routeName] =
    !expandedAccordionList.value[routeName];
};

const setInitialExpansion = () => {
  SIDE_BAR_ITEMS.forEach((item) => {
    const childSelected = item.children?.some(
      (child) => child.routeName === route.name
    );
    if (childSelected) {
      initiallyExpanded.value[item.routeName] = true;
    }
  });
};

const expandAccordion = (item: SideBarItemsType) => {
  if (initiallyExpanded.value[item.routeName] !== undefined) {
    return initiallyExpanded.value[item.routeName];
  }
  return !!expandedAccordionList.value[item.routeName];
};

onMounted(() => setInitialExpansion());
</script>

<template>
  <TwoSidebar :route="route" :router="router">
    <template #header>
      <div class="pb-7 pt-14">
        <router-link to="/">
          <img :src="RunaLogoWhite" class="w-24" />
        </router-link>
        <CustomerSelectDropdown class="mt-6" />
        <CreateNewRewardLinkButton
          name="create-new-reward"
          class="mt-8"
          v-permissions="{ permissions: [UserPermissions.WRITE_ORDERS] }"
          v-hide-in-playground
        />
      </div>
    </template>

    <template v-for="item in SIDE_BAR_ITEMS">
      <TwoSidebarItem
        v-if="
          isEnabledSideBar(item) &&
          hasPermission(
            item.permissionGuards?.permissions || [],
            item.permissionGuards?.guardAction || GuardAction.AND
          )
        "
        :routeName="item.routeName"
        :trackingEvent="item.displayName"
        :trackingProperties="{
          customerId: globalStore.customerId,
          isMigratedCustomer: customerIsMigratedFromConnect.valueOf(),
        }"
        :icon="item.icon"
        class="two-sidebar-item"
        :isAccordion="item.children && item.children.length > 0"
        :expanded="expandAccordion(item)"
        :key="item.routeName"
        @accordion-click="handleAccordionClick"
      >
        {{ item.displayName }}
        <Badge
          v-if="item.isBeta"
          class="relative ml-1 bg-grey-800 text-xs tracking-widest text-white"
          >BETA</Badge
        >
        <TwoTag
          bgColor="bg-red-500"
          class="ml-2 px-[8px] pb-[2px] pt-[0px] leading-4"
          v-if="item.showNewTag && store.shouldShowNewTag(item.routeName)"
        >
          <span class="mt-2 text-[10px] text-white">NEW</span>
        </TwoTag>
      </TwoSidebarItem>
      <li
        v-if="item.children"
        class="child-container"
        :style="{
          maxHeight: expandAccordion(item) ? childAccordionListHeight : '',
        }"
      >
        <ul v-for="child in item.children">
          <TwoSidebarItem
            v-if="
              isEnabledSideBar(child) &&
              hasPermission(
                child.permissionGuards?.permissions || [],
                child.permissionGuards?.guardAction || GuardAction.AND
              ) &&
              customerHasBalancesEnabled(child)
            "
            :routeName="child.routeName"
            :trackingEvent="child.displayName"
            :trackingProperties="{
              customerId: globalStore.customerId,
              isMigratedCustomer: customerIsMigratedFromConnect.valueOf(),
            }"
            :icon="child.icon"
            :key="child.routeName"
            class="two-sidebar-item child-item"
          >
            {{ child.displayName }}
            <TwoTag
              bgColor="bg-red-500"
              class="ml-2 px-[8px] pb-[2px] pt-[0px] leading-4"
              v-if="child.showNewTag && store.shouldShowNewTag(child.routeName)"
            >
              <span class="mt-2 text-[10px] text-white">NEW</span>
            </TwoTag>
          </TwoSidebarItem>
        </ul>
      </li>
    </template>

    <template #footer>
      <div class="mb-3 mt-4">
        <TwoButton name="logout" class="btn-dark" @click="logout"
          >Log Out</TwoButton
        >
        <router-link
          :to="{ name: 'debug-feature-toggles' }"
          active-class="active"
          v-if="isUserStaff"
          class="text-lg text-grey-200 no-underline"
        >
          ⚙
        </router-link>
      </div>
    </template>
  </TwoSidebar>
</template>

<style lang="sass" scoped>
.child-container
  max-height: 0
  overflow: hidden
  transition: max-height 0.2s ease-out
.child-item
  padding-left: 52px
.two-sidebar-item
  :deep(button)
    display: flex
    align-items: center
</style>
